import * as React from 'react'
import Layout from '../../components/layout'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

const Post = (props) => {
  let data = props.data;
  console.log(Array.isArray(data.mdx.frontmatter.tags));
  return (
    // <Layout pageTitle={data.mdx.frontmatter.title}>
    //   <p>{data.mdx.frontmatter.datePublished}</p>
    //   <MDXRenderer>
    //     {data.mdx.body}
    //   </MDXRenderer>
    // </Layout>
    <Layout>
      <section className="post">
        <header className="post-header">{data.mdx.frontmatter.title}</header>
        <p className="post-date">{data.mdx.frontmatter.datePublished}</p>
        {
          data.mdx.frontmatter.tags.map((tag, i) => {
            return <span className="tag">#{tag}{i === data.mdx.frontmatter.tags.length - 1 ? '' : ','}</span>
          })
        }
        <section className="post-content">
          <MDXRenderer scope={{ React, Layout }}>
            {data.mdx.body}
          </MDXRenderer>
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        datePublished(formatString: "MMMM D, YYYY")
        tags
      }
      body
    }
  }
`

export default Post
